<section class="p-2">
  <div *ngIf="notificationList?.length === 0" class="no-notifications">
    <p>No notifications available</p>
  </div>
<div *ngFor="let notification of notificationList" class="notification-card" >
  <div class="notification-item">
    <div class="icon">
      <mat-icon color="primary" *ngIf="notification.notificationType === 'commentAdded'">notifications</mat-icon>
      <mat-icon color="primary" *ngIf="notification.notificationType === 'statusChange'">autorenew</mat-icon>
    </div>
    <div class="notification-content">
      <span class="title">{{notification.type ==='statusChange'?"Status Update":"New Comment" }}</span>
      <p> {{notification.notificationText}} </p>
      <span class="time">{{notification.createdAt | date : "MM/dd/yyyy" || "-"  }}</span>
    </div>
  </div>
</div>
</section>