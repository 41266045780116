import { PushNotificationService } from "src/app/features/myuss/services/push-notification/push-notification.service";
import { Component, effect } from "@angular/core";
import { AppNotifications } from "src/app/models/notification-model";
import { ToastrService } from "ngx-toastr";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";

@Component({
  selector: "app-push-notification",
  templateUrl: "./push-notification.component.html",
  styleUrls: ["./push-notification.component.scss"],
})
export class PushNotificationComponent {
  notificationList: AppNotifications[];
  selectedAccountId;

  constructor(
    private pushNotificationService: PushNotificationService,
    private toaster: ToastrService,
    private profileService: ProfileService
  ) {
    effect(() => {
      this.selectedAccountId = this.profileService.selectedAccount().accountId;
      this.getPushNotifications();
    });
  }

  ngOnInit() {}

  getPushNotifications() {
    this.pushNotificationService
      .getPushNotifications(this.selectedAccountId)
      .subscribe({
        next: (res) => {
          if (res) {
            this.notificationList = res.sort((a, b) => {
              return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
              );
            });
          }
        },
        error: (error) => {
          this.toaster.error("Error fetching notifications:", error);
        },
      });
  }
}
